define("additive-mi/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "aiButton": {
      "loadingProgress": "Loading..."
    },
    "analytics": {
      "description": "Every visitor interaction on and with your website and landing pages strengthens the awareness of your product as well as your brand and will ideally lead to direct or indirect sales in the near or later future. As every access and interaction are contributing to your success it is important to acknowledge their involvement.<br/>ADDITIVE makes this contribution measurable by assigning it real monetary value and reporting it as \"value added\".</br></br>All interactions on your website and landing pages carried out in the selected period are analyzed and assigned to their corresponding advertising campaigns and/or access sources. For inquiries and online bookings, the resulting reservations and revenue amounts are determined. If advertising accounts are linked, the corresponding expenses are calculated and assigned to the advertising campaigns.",
      "filterButton": "Filter",
      "title": "Reports"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "INQUIRIES",
        "presentationName": "ADDITIVE+ INQUIRIES"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "VOUCHERS",
        "presentationName": "ADDITIVE+ VOUCHERS"
      },
      "4": {
        "name": "CONTENTS",
        "presentationName": "ADDITIVE+ CONTENTS"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GUEST INTRANET",
        "presentationName": "ADDITIVE+ GUEST INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Deleted User",
      "description": "Find the chronological list of all your users' activities within the app below. Please note that the users must be part of your organization for you to see their activities.",
      "empty": "No activities available",
      "entries": "Entries",
      "page": "Page",
      "time": "{time}",
      "title": "Activity Log"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "campaign-reports": {
          "report": {
            "routeName": "Campaign report"
          },
          "routeName": "Campaign reports"
        },
        "dashboard": {
          "routeName": "MARKETING INSIGHTS"
        }
      }
    },
    "components": {
      "AmiAttributionTableButton": {
        "detail": "Details"
      },
      "amiAttributionTableDetailDialog": {
        "chart": {
          "title": "Distribution on Monthly Basis"
        }
      },
      "amiFilters": {
        "attributionModes": {
          "label": "Attribution Method",
          "placeholder": "Please select an attribution method."
        },
        "conversionTypes": {
          "label": "Conversion Types",
          "placeholder": "Please select a conversion type."
        },
        "groupingHierarchy": {
          "label": "Calculation Order",
          "options": {
            "campaign": "Campaign",
            "medium": "Medium",
            "source": "Source"
          }
        },
        "groupings": {
          "label": "Groupings",
          "placeholder": "Add groupings"
        },
        "hideableColumns": {
          "label": "Columns",
          "options": {
            "actionTracking": "Events",
            "campaignBudgets": "Ad Spend",
            "conversionRates": "Conversion Rates",
            "conversionTypes": "Conversion Types",
            "turnoverPerTypes": "Revenue Amounts by Conversion Type"
          },
          "placeholder": "Add column"
        },
        "reservationTypes": {
          "label": "Include Reservation Types",
          "name": "Reservation Types",
          "options": {
            "cancelled": "Cancellations",
            "option": "Future Options"
          },
          "placeholder": "Add reservation types"
        },
        "sessionBasis": {
          "label": "[BETA] Session basis",
          "options": {
            "cookieless": "[BETA] Cookieless",
            "cookies": "Cookies"
          },
          "placeholder": "Add session basis"
        },
        "title": "Filter"
      },
      "amiManagedFetch": {
        "error": "An unexpected error occurred.",
        "retry": "Retry"
      },
      "amiNotifications": {
        "alertTypes": {
          "guests-upload": "Failed people upload in ADDITIVE+ CRM at: ",
          "reservations-upload": "Failed reservations upload in ADDITIVE+ CRM at: ",
          "subscriber-upload": "Failed subscriber upload at: "
        }
      },
      "amiReports": {
        "errorPage": {
          "error": "The campaign report is no longer available."
        },
        "list": {
          "affectedMonths": "Affected months",
          "automatedEmpty": "There are no automated campaign reports available yet.",
          "card": {
            "actions": {
              "deleteReport": {
                "description": "Do you want to delete the campaign report? This action cannot be undone.",
                "title": "Delete campaign report"
              },
              "publishReport": {
                "publish": "Do you want to publish the campaign report?",
                "title": "Publish campaign report",
                "unpublish": "Do you want to unpublish the campaign report?"
              },
              "recalculateReport": {
                "description": "Do you want to recalculate the campaign report?",
                "title": "Recalculate campaign report"
              },
              "revokeShare": {
                "description": "If you continue, this share link will be revoked. The people who have received the report can then no longer access it via the old share link.",
                "title": "Attention!"
              },
              "shareReport": {
                "actions": {
                  "create": "Create new sharing link"
                },
                "create": "Create a new sharing link for the campaign report and share it with others.",
                "description": "Copy this link to share the campaign report with others.",
                "error": "An error occurred while loading the sharing link. Please try again.",
                "regenerate": "Be aware that revoking or creating a new sharing link means that the old sharing link will no longer work and people who received the report can no longer access it.",
                "title": "Share campaign report"
              },
              "showConfiguration": "Show configuration",
              "slideshow": "Slideshow"
            },
            "nonPublished": "Not published",
            "published": "Published"
          },
          "customEmpty": "There are no custom campaign reports available yet.",
          "error": "An error occurred while loading the campaign reports. Please try again."
        },
        "settingsNavdrawer": {
          "defaultTitle": "Default settings",
          "description": "Here you can change the default settings for new campaign reports. To view the settings for a specific campaign report, open the report and click \"Show configuration\" in the respective preview.",
          "labels": {
            "averageReservationRevenue": "Average reservation revenue",
            "calculateConversionValuesAsRevenue": "Calculate conversion values as revenue",
            "calculateNewsletterOpenings": "Include newsletter campaigns",
            "calculateRoi": "Calculate A+ROI",
            "cancellationsAsReservations": "Cancellations as reservations",
            "conversionRateRequestsToReservations": "Conversion rate requests to reservations",
            "description": "Performance Recap",
            "headerImage": "Header image",
            "includeHosts": "Include direct/not_set from these hosts",
            "optionsAsReservations": "Options as reservations",
            "presentation": "Presentation",
            "sessionBasis": "Session basis",
            "showCharts": "Show charts",
            "showDescriptionSlide": "Show performance recap slide",
            "showHistoricalDataCharts": {
              "isNotAllowed": "This setting is only available if at least two reporting months exist.",
              "title": "Show monthly comparison table"
            },
            "showKpiInfo": "Show KPI description",
            "showNewsletterMarketingSlide": "Show newsletter marketing slide",
            "useEmpiricalDataToCalculateRevenue": "Use empirical data to calculate revenue",
            "useReservationData": "Use reservation data"
          },
          "reportTitle": "Report settings",
          "values": {
            "cookieless": "Cookieless",
            "cookies": "Cookies",
            "false": "No",
            "true": "Yes"
          }
        }
      },
      "amiSettings": {
        "temporalSettingsList": {
          "budgets": {
            "active": "Current budget",
            "budgetMissingWarning": "No budget has been defined. Please remember to define the budget so that the key figures in the campaign report are calculated correctly.",
            "currentBudgetInfo": "<div>The current budget is <strong>{amount}</strong>.</div><br/>This value will be used for future reports if not defined otherwise.",
            "deleteDialog": {
              "description": "This budget will be deleted permanently. Do you want to proceed?",
              "title": "Delete budget"
            },
            "description": "Here you can define budgets per month. These are used to calculate the key figures in the campaign reports.",
            "empty": "There is no budget defined.",
            "future": "Future budgets",
            "past": "Past budgets"
          },
          "detailDialog": {
            "budgets": {
              "title": "Budget"
            },
            "changeDate": "Change date",
            "date": "Date",
            "dateMonth": "Month",
            "dateYear": "Year",
            "note": "Note",
            "percentage": "%",
            "roundingInfo": "The value will be rounded to two decimal places",
            "serviceCosts": {
              "title": "Service costs"
            },
            "surcharge": "Additional management fee",
            "value": "Value"
          },
          "serviceCosts": {
            "active": "Current service costs",
            "currentServiceCostsInfo": "<div>The current service cost is <strong>{amount}</strong>.</div><br/>This value will be used for future reports if not defined otherwise.",
            "currentServiceCostsWarning": "No service costs have been defined. Please remember to define the service costs so that the key figures in the campaign report are calculated correctly.",
            "deleteDialog": {
              "description": "This service cost will be deleted permanently. Do you want to proceed?",
              "title": "Delete service costs"
            },
            "description": "Here you can define service costs per month. These are used to calculate the key figures in the campaign reports.",
            "empty": "There are no service costs defined.",
            "future": "Future service costs",
            "past": "Past service costs"
          }
        }
      },
      "ui-metric-item": {
        "comparePeriod": "to previous year",
        "placeholder": "No change compared to prev. year"
      }
    },
    "conversionAttribution": {
      "description": "All conversions on your website and landing pages recorded in the selected period are analyzed and assigned to the \"involved\" advertising campaigns and/or access sources using the selected attribution method.<br/>For inquiries and online bookings, the resulting reservations and revenue amounts are determined and attributed based on the selected attribution method.",
      "filterButton": "Filter",
      "title": "Conversion Attribution"
    },
    "dashboard": {
      "conversions": {
        "bookings": "Online Bookings",
        "conversions": "Online Conversions",
        "description": "The various online conversions created on your website and landing pages can be tracked via ADDITIVE tracking script and its conversion tracking.",
        "enquiries": "Inquiries",
        "leads": "Leads",
        "newsletterSubscriptions": "Newsletter Subscriptions",
        "other": "Other",
        "title": "Online Conversions",
        "vouchers": "Voucher Purchases"
      },
      "description": "In the following, you will get an overview of key metrics such as interactions, conversions and revenue achieved on your website and landing pages in the selected period. Please note that the ADDITIVE tracking script must be implemented for this.",
      "filters": {
        "hosts": {
          "label": "Channel",
          "placeholder": "All"
        }
      },
      "information": {
        "title": "General tracking information"
      },
      "keyIndicators": {
        "conversions": "Online Conversions",
        "reservations": "Reservations",
        "sessions": "Sessions",
        "turnover": "Revenue from Reservations"
      },
      "performance": {
        "avgEnquiryValue": "ø Inquiry Value",
        "avgReservationsTurnover": "ø Reservation Revenue",
        "avgSessionValue": "ø Session Value",
        "description": "Below you can see the conversion rates for sessions to inquiries and inquiries to reservations as well as the resulting average monetary values of each action.",
        "enquiryBookingRatio": "Inquiries to Reservations",
        "sessionsEnquiryRatio": "Sessions to Inquiries",
        "title": "Performance Indicators"
      },
      "reservations": {
        "description": "When determining reservations, all recorded online conversions are matched with the reservation data from the connected PMS. Based on historical data, the person making the reservation is then classified as either a \"new\" or a \"returning\" guest.",
        "newGuests": "New Guests",
        "reservations": "Reservations",
        "returningGuests": "Returning Guests",
        "showReservations": "Show reservations",
        "title": "Reservations"
      },
      "salutations": {
        "afternoon": "Good afternoon",
        "evening": "Good evening",
        "morning": "Good morning"
      },
      "title": "Dashboard",
      "upgradeMessage": {
        "buttonText": "More information",
        "plan": "PRO",
        "text": "Increased revenue, efficiency and success thanks to automation. Intelligent marketing with the marketing software created specifically for hotels.",
        "title": "MARKETING AUTOMATION"
      }
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS integrations",
            "campaigns": "Campaigns",
            "comments": "Comments",
            "landingPages": "Landing pages",
            "leadIntegrations": "Lead integrations",
            "pmsIntegrations": "PMS integrations",
            "posts": "Posts",
            "settings": "Settings",
            "surveys": "Surveys",
            "thirdPartyTokens": "ADDITIVE+ APPS interfaces",
            "usps": "USPs",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Content",
            "contentType": "Content Type"
          }
        },
        "description": "The object can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this object.",
        "title": "The object is in use"
      },
      "discardChanges": {
        "continueEditing": "Continue editing",
        "discardAction": "Discard",
        "message": "Do you want to discard the unsaved changes?",
        "title": "Attention!"
      },
      "error": {
        "message": "An unexpected error has occurred. Please try again later.",
        "title": "Something went wrong"
      },
      "noPermission": {
        "message": "You do not have permission to perform this action. Please contact your administrator.",
        "title": "Missing permission"
      }
    },
    "enquiryAttribution": {
      "date": {
        "description": "In the following table, all inquiries made on your website and landing pages that can be found within ADDITIVE+ ANFRAGEN are analyzed and assigned to the corresponding advertising campaigns. Additionally, all resulting reservations are determined.",
        "title": "Attribution by Date of Inquiry"
      },
      "description": "In the following table, all inquiries made on your website and landing pages that can be found within ADDITIVE+ ANFRAGEN are analyzed and assigned to the corresponding advertising campaigns. Additionally, all resulting reservations are determined.<br><br>All inquiries received in the period under review are considered for this.",
      "detail": "Details",
      "navigation": {
        "date": {
          "description": "Taken into consideration are all inquiries generated during the period under review.",
          "title": "Inquiry Date"
        },
        "stay": {
          "description": "Taken into consideration are all inquiries with an arrival date in the period under review.",
          "title": "Arrival Date"
        }
      },
      "stay": {
        "description": "In the following table, all inquiries made on your website and landing pages that can be found within ADDITIVE+ ANFRAGEN are analyzed and assigned to the corresponding advertising campaigns. Additionally, all resulting reservations are determined.<br><br>Taken into consideration are all inquiries with an arrival date in the period under review.",
        "title": "Attribution by Stay"
      },
      "title": "Inquiry Attribution"
    },
    "global": {
      "actions": {
        "activate": "Enable",
        "add": "Add",
        "close": "Close",
        "configure": "Configure",
        "copy": "Copy",
        "deactivate": "Disable",
        "delete": "Delete",
        "disconnect": "Disconnect",
        "dismiss": "Dismiss",
        "download": "Download",
        "filter": "Filter",
        "generateUrl": "Generate new link",
        "hide": "Hide",
        "logout": "Log out",
        "ok": "OK",
        "publish": "Publish",
        "recalculate": "Recalculate",
        "retry": "Retry",
        "revokeUrl": "Revoke link",
        "save": "Save",
        "share": "Share",
        "show": "Show",
        "switch": "Switch",
        "unpublish": "Unpublish",
        "upgrade": "Upgrade"
      },
      "dates": {
        "days": "{value, plural, =1 {day} other {days}}",
        "hours": "{value, plural, =1 {hour} other {hours}}",
        "minutes": "{value, plural, =1 {minute} other {minutes}}",
        "months": "{value, plural, =1 {month} other {months}}",
        "seconds": "{value, plural, =1 {second} other {seconds}}",
        "years": "{value, plural, =1 {year} other {years}}"
      },
      "empty": "No data available",
      "errors": {
        "email": "Invalid email address",
        "invalidFormat": "Invalid format",
        "positiveFloatOrZero": "Value must be positive with max. 2 decimal places or 0",
        "positiveInteger": "The value must be a positive integer.",
        "positiveNumber": "The value must be a positive number.",
        "rate": "The value must have the format 10:1.",
        "required": "The value must not be empty.",
        "requiredPositiveInteger": "The value must not be empty and must be a positive integer.",
        "requiredRate": "The value must not be empty and must have the format 10:1.",
        "title": "An error occurred",
        "tooManyKpis": "A maximum of 6 activated KPIs can be active per column."
      },
      "languages": {
        "de": "German",
        "en": "English",
        "fr": "French",
        "it": "Italian",
        "nl": "Dutch Language"
      },
      "properties": {
        "image": "Image",
        "subject": "Subject",
        "text": "Text"
      },
      "states": {
        "active": "Active",
        "inactive": "Not active",
        "loading": "Loading..."
      },
      "toast": {
        "error": {
          "savedChanges": "An unexpected error occurred"
        },
        "success": {
          "savedChanges": "Action was successful"
        },
        "update": {
          "actionLabel": "Reload",
          "description": "Unsaved changes will be lost.",
          "title": "Update available"
        }
      },
      "warnings": {
        "multimedia": {
          "image": "Only images may be selected."
        }
      }
    },
    "languageSelect": {
      "add": "Add language",
      "ar": "Arabic",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch",
      "pl": "Polish",
      "remove": "Remove language",
      "removeDescription": "Do you really want to remove the language {language}?",
      "ru": "Russian"
    },
    "mmAttributesDialog": {
      "alt": "Alt attribute",
      "dialogTitle": "Image attribute",
      "helpText": "The title tag of an image is the text that is displayed when the mouse moves over the image. The alt attribute is used by search engines to classify the content of the image and may also be displayed if the image cannot be loaded.",
      "save": "Save",
      "title": "Title"
    },
    "mmBulkDownload": {
      "confirm": {
        "description": "Do you want to download this folder? The download link will be sent to you via email.",
        "title": "Download folder"
      },
      "download": "Download",
      "error": {
        "description": "The download of the selected medium is not supported.",
        "title": "Warning"
      },
      "success": {
        "description": "The download link will be sent to your email address.",
        "title": "Download successfully started"
      }
    },
    "mmCropperView": {
      "save": "Save"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# hour} other {# hours}}",
        "minutes": "{count, plural, =1 {# minute} other {# minutes}}",
        "seconds": "{count, plural, =1 {# second} other {# seconds}}"
      },
      "insertSearchParam": "Enter a search term",
      "noResults": "No results found",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 image} other {# images}}",
          "description": "Enter a search term here to find images on Unsplash.",
          "name": "Unsplash",
          "save": "Add images",
          "title": "Add Unsplash images"
        },
        "youtube": {
          "count": "{count, plural, =1 {1 video} other {# videos}}",
          "description": "Enter a search term here to find the desired YouTube video or simply enter the corresponding URL.",
          "name": "YouTube",
          "save": "Add video",
          "title": "Add YouTube video"
        }
      }
    },
    "mmImageTransforms": {
      "brightness": "Brightness",
      "contrast": "Contrast",
      "editDialog": {
        "attributes": {
          "description": "Define the description text and alternative text for the image",
          "title": "Set image attributes"
        },
        "imageTransform": {
          "description": "Use this to edit the image",
          "title": "Edit image"
        }
      },
      "imageLoadError": "An error occurred while loading the image, please try again later.",
      "saturation": "Saturation",
      "setFocusPoint": "Set focus point",
      "title": "Edit image"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "download": "Download",
      "error": "The file could not be loaded",
      "more": "More"
    },
    "mmResourceGallery": {
      "isImageAddable": "Add image",
      "isImageOrVideoAddable": "Add image or video",
      "isVideoAddable": "Add video"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Add",
        "bulkDelete": "Delete",
        "bulkDownload": "Download",
        "delete": "Delete",
        "move": "Move here",
        "remove": "Remove",
        "rename": "Rename",
        "retry": "Retry",
        "save": "Save"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Are you sure you want to delete this folder and its contents irrevocably?",
          "title": "Delete folder"
        },
        "confirmDownloadFolder": {
          "description": "Do you want to download this folder?  The download link will be sent to you via email.",
          "title": "Download Folder"
        },
        "confirmRemoveFolder": {
          "description": "Are you sure you want to remove this shared folder and its contents?",
          "title": "Remove shared folder"
        },
        "deleteFolderIncomplete": {
          "description": "The folder could not be deleted completely because some of its files are currently in use. In total {count, plural, =1 {# File} other {# Files}} were deleted.",
          "title": "Folder could not be deleted"
        },
        "removeFolderError": {
          "description": "The folder could not be removed because some of its files are currently in use.",
          "title": "Folder could not be removed"
        },
        "youtubeDownloadError": {
          "description": "The download of the selected medium is not supported.",
          "title": "Attention"
        }
      },
      "errors": {
        "required": "Value can't be empty"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDialog": {
            "description": "Are you sure you want to delete {count, plural, =1 {this medium} other {this media}}",
            "title": "Delete {count, plural, =1 {medium} other {media}}"
          },
          "conflict": {
            "description": "One or more media cannot be deleted because they are currently used in content. To be able to delete these media, you must first remove them from all content.",
            "title": "Not all media are deletable"
          },
          "disabled": "Shared folders and files cannot be deleted.",
          "error": {
            "description": "The selected media cannot be deleted because they are currently being used in content. To be able to delete these media, you must first remove them from all content.",
            "title": "Media are in use"
          }
        },
        "bulkDownload": {
          "confirmDialog": {
            "description": "Are you sure you want to delete {count, plural, =1 {this medium} other {this media}}. The download link will be sent to you via email.",
            "title": "Download {count, plural, =1 {medium} other {media}}"
          },
          "disabled": "Shared folders and files cannot be downloaded."
        },
        "bulkMove": {
          "disabledParentFolder": "Folders cannot be moved into themselves.",
          "disabledSharedFolder": "Folders and files cannot be moved within shared folders.",
          "disabledSharedSelection": "Shared folders and files cannot be moved."
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Are you sure you want to delete this medium irrevocably?",
            "title": "Delete medium"
          },
          "conflict": {
            "description": "The medium cannot be deleted, as it is currently used in a content. To delete this medium, you must first remove it from all contents.",
            "title": "Medium is in use"
          }
        },
        "folder": "Folder",
        "media": "Files",
        "new": {
          "addSharedFolder": {
            "title": "Add shared folder"
          },
          "externalSearch": {
            "title": "External media"
          },
          "folder": {
            "title": "Create folder"
          },
          "title": "Add",
          "upload": {
            "title": "Upload file"
          }
        },
        "sharedFolder": "Shared folder",
        "sharedMedia": "Shared files",
        "uploading": {
          "description": "While an upload is running, nothing else can be uploaded.",
          "title": "Uploading..."
        }
      },
      "mmAddSharedFolderDialog": {
        "errors": {
          "add": "An error occurred while adding the shared folders. Please try again.",
          "fetch": "An error occurred while loading the shared folders. Please try again."
        },
        "title": "Add shared folder"
      },
      "mmFileUpload": {
        "error": {
          "title": "Upload error"
        },
        "maxConcurrentFilesUploaded": {
          "description": "You can upload at most {count} files during a single upload. You can upload the rest after the current upload process is finished.",
          "title": "Limit for simultaneous upload reached"
        },
        "maxSizeDialog": {
          "description": "One or more files you want to upload exceed the maximum size of 10MB, they will not be uploaded.",
          "title": "Exceeding maximum size"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Uploading cancelled"
        },
        "errorDialog": {
          "description": "{failed} of {count} could not be uploaded, because they are either not supported or an unexpected error has occurred.",
          "title": "Upload error"
        },
        "progress": {
          "description": "Files are uploaded",
          "title": "Uploading"
        },
        "success": {
          "title": "Uploading successful"
        }
      },
      "mmFilter": {
        "mr": "Greater than",
        "noMatches": "Your filtering did not yield any resuts",
        "searchPlaceholder": "Search...",
        "type": {
          "title": "File type",
          "types": {
            "file": "File",
            "image": "Image",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "folder": {
            "create": "Create folder",
            "edit": "Rename folder"
          },
          "sharedFolder": {
            "create": "Create shared folder",
            "edit": "Rename shared folder"
          }
        }
      },
      "toasts": {
        "bulkDownloadSuccess": {
          "description": "The download link will be sent to your email address",
          "title": "Download sucessfully started"
        },
        "folderDeleteSuccess": {
          "description": "The folder was successfully deleted",
          "title": "Folder deleted"
        },
        "success": {
          "title": "Action performed successfully"
        }
      },
      "warnings": {
        "maxCount": "Only {count, plural, =1 {# element} other {# elements}} can be selected.",
        "recommendedSize": {
          "action": "Add anyway",
          "message": "In your selection there are files that do not meet the recommended minimum size.",
          "title": "Attention"
        }
      }
    },
    "onboarding": {
      "1": {
        "description": "Ongoing performance measurement and a corresponding results analysis are essential for successful campaign planning and management. ADDITIVE+ MARKETING INSIGHTS provides you with the necessary data.",
        "title": "Measuring your Marketing Success"
      },
      "2": {
        "description": "Thanks to the data exchange with your hotel software, conversions across various advertising channels can be assigned to their real corresponding reservations and revenue amounts. The results are presented in clear and easily comprehensible evaluations.",
        "title": "Attribution"
      },
      "3": {
        "description": "",
        "title": "Evaluate channels"
      }
    },
    "pageNotFound": {
      "button": "Homepage",
      "content": "The page you were looking for could not be found!<br>The URL may be incorrect or the page may have been removed or renamed.",
      "title": "Page not found!"
    },
    "reports": {
      "create": {
        "error": {
          "endBeforeStart": "Must not be before start date",
          "endInFuture": "Date has to be in the past",
          "nameRequired": "Name must not be empty",
          "title": "Error"
        },
        "name": "Name",
        "title": "Create custom campaign report"
      },
      "description": "For targeted and high-performance marketing, continuous evaluations and adjustments to the campaigns are necessary. To do this, our experts dive deep into the detailed evaluation tools from ADDITIVE and the analysis tools of the advertising platforms.<br/><br/>In order to get a quick and easy-to-understand overview, the most important key figures in the following reports show the overall results of the marketing measures.",
      "download": {
        "toasts": {
          "error": "Downloading PDF failed",
          "loading": {
            "description": "PDF is being generated and will be downloaded shortly.",
            "title": "Downloading PDF"
          },
          "success": "PDF successfully downloaded"
        }
      },
      "edit": {
        "addKpi": "Add custom value",
        "attributionSettings": "Attributions settings",
        "calculation": "Calculation",
        "columns": {
          "firstColumn": "Column 2",
          "secondColumn": "Column 3",
          "thirdColumn": "Column 4"
        },
        "configuration": "Configuration",
        "descriptionNotice": {
          "description": "Since the report has been recalculated, it is possible that the performance recap text is no longer up to date. Please review the performance recap.",
          "title": "Recalculation was successful"
        },
        "display": "Display",
        "kpiTypes": {
          "currency": "Monetary value",
          "number": "Number",
          "percentage": "Percentage",
          "ratio": "Ratio (x:1)"
        },
        "labels": {
          "configuration": {
            "averageReservationRevenue": "Average reservation revenue",
            "budget": "Budget",
            "calculateConversionValuesAsRevenue": "Calculate conversion values as revenue",
            "calculateNewsletterOpenings": "Include newsletter campaigns",
            "calculateRoi": "Calculate A+ROI",
            "cancellationsAsReservations": "Cancellations as reservations",
            "conversionRateRequestsToReservations": "Conversion rate requests to reservations",
            "dateRangeDataEmpty": "One or more months of the selected date range do not contain any statistical data. Please consider adjusting the range.",
            "dateRangeServiceCostsEmpty": "You have not yet defined costs associated with the dates selected above. Please consider adding monthly costs.",
            "endDate": "End date",
            "includeHosts": {
              "description": "Sessions, conversions, and attributed bookings from visitors who access the host directly or through unknown campaigns will be included.",
              "title": "Include direct/not_set from these hosts"
            },
            "month": "Month",
            "monthCount": {
              "budget": "Total budget of {count} {count, plural, =1 {month} other {months}}",
              "serviceCost": "Total service costs of {count} {count, plural, =1 {month} other {months}}"
            },
            "optionsAsReservations": "Options as reservations",
            "serviceCost": "Service cost",
            "sessionBasis": {
              "cookieless": "Cookieless",
              "cookies": "Cookies",
              "title": "Session Basis"
            },
            "showCharts": "Show charts",
            "showDescriptionSlide": "Show performance recap slide",
            "showHistoricalDataCharts": {
              "isNotAllowed": "This setting is only available if at least two reporting months exist.",
              "isReadOnly": "This setting is not supported for reports with multiple months.",
              "title": "Show monthly comparison table"
            },
            "showKpiInfo": "Show KPI description",
            "showNewsletterMarketingSlide": {
              "isReadOnly": "This setting is not supported if the setting \"Include newsletter campaigns\" is deactivated.",
              "title": "Show newsletter marketing slide"
            },
            "startDate": "Start date",
            "useEmpiricalDataToCalculateRevenue": "Use empirical data to calculate revenue",
            "useReservationData": {
              "description": "If this setting is active, reservation data is used as KPIs (new and recurring reservations).",
              "title": "Use reservation data"
            },
            "year": "Year"
          },
          "description": "Performance Recap",
          "headerImage": "Header image",
          "kpis": {
            "label": "Label",
            "type": "KPI type",
            "value": "Value"
          },
          "roi": {
            "description": "Description"
          },
          "title": "Title"
        },
        "mainColumn": "Main column",
        "originalValue": "Original value",
        "roi": "A+ROI",
        "saveDialog": {
          "checkboxLabel": "Save current layout as default",
          "recalculationRequiredDescription": "<b>Caution!</b><br>If you save the report, it will be recalculated automatically. Do you want to proceed?",
          "saveLayoutDescription": "You are about to save the report.<br>Would you like to apply the current layout (visibility/order) of the KPIs as the default for future reports of this type?",
          "title": "Save report"
        },
        "slides": "Slides",
        "totalValueAdded": "Total value added",
        "useReservationDataDialog": {
          "description": "If this setting is active, the kpi layout is changed and the kpis \"Reservations\", \"New reservations\" and \"Recurring reservations\" are used. Furthermore, all custom kpis will be hidden. Would you like to continue?",
          "title": "Use reservation data"
        }
      },
      "notices": {
        "errors": {
          "budget": {
            "action": "Add budget"
          },
          "report_generation_day_setting": {
            "action": "To settings"
          },
          "service-costs": {
            "action": "Add service costs"
          }
        }
      },
      "tabs": {
        "automated": "Automated",
        "custom": "Custom"
      },
      "title": "Campaign Reports"
    },
    "routes": {
      "analytics": {
        "title": "Reports"
      },
      "auditlog": {
        "title": "Activity Log"
      },
      "conversionAttribution": {
        "title": "Conversion Attribution"
      },
      "dashboard": {
        "title": "Dashboard"
      },
      "enquiryAttribution": {
        "title": "Inquiry Attribution"
      },
      "reports": {
        "title": "Campaign reports"
      },
      "settings": {
        "budget": "Budget",
        "general": "General",
        "reports": "Campaign reports",
        "serviceCosts": "Service costs",
        "snippet": "A+ APPS Snippet",
        "title": "Settings"
      },
      "stayAttribution": {
        "title": "Stay Attribution"
      }
    },
    "select": {
      "empty": "No option found",
      "emptyOption": "No selection",
      "noResults": "No results found",
      "searchPlaceholder": "Search"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# element} other {# elements}} selected"
    },
    "settings": {
      "analytics": {
        "label": "ADDITIVE+ ANALYTICS"
      },
      "attributions": {
        "averageReservationRevenue": {
          "description": "Amount in Euro",
          "title": "Average Reservation Revenue"
        },
        "calculateConversions": {
          "modalDescription": "When this setting is active, the online bookings captured with the ADDITIVE+ APPS Snippet are considered as reservations in the booking process, and the recorded booking revenues are added to the reservation revenues.<br><br><div><span class=\"font-medium\">Important!</span> This leads to the fact that even bookings from which no actual reservations have resulted (e.g., due to cancellation) are still counted.</div><br/><i>This function should generally only be activated when no attribution from online bookings to PMS reservations is possible (e.g., when no PMS data is available in ADDITIVE+ CRM).</i>",
          "modalTitle": "Attention!",
          "title": "Calculate Conversion Values of SalesConversions as Revenue"
        },
        "conversionRate": {
          "description": "Two numbers separated with a colon (e.g. 10:1)",
          "title": "Conversion Rate Requests to Reservations"
        },
        "includeHosts": {
          "description": "Sessions, conversions, and attributed bookings from visitors who access the host directly or through unknown campaigns will be included.",
          "title": "Include direct/not_set from these hosts"
        },
        "label": "Attributions",
        "useEmpiricalData": {
          "modalDescription": "When this setting is active, the reservation data from ADDITIVE+ CRM is completely ignored. Instead, the following values are used to calculate how many reservations and what reservation revenue should have resulted from the inquiries:<ul><li>Conversion rate of inquiries to reservations</li><li>Average reservation revenue</li></ul><br/><div><span class=\"font-medium\">Important!</span> The calculated values do not reflect the real situation and are approximations. Both calculation values must therefore be determined very carefully and individually for each customer.</div><br/><i>This function should generally only be activated when no attribution from online bookings to PMS reservations is possible (e.g. when no PMS data is available in ADDITIVE+ CRM).</i>",
          "modalTitle": "Attention!",
          "title": "Use Empirical Data to calculate Revenues of SalesOpportunityConversions"
        }
      },
      "facebookAds": {
        "adAccount": {
          "input": "Ad Accounts",
          "label": "Facebook Ad Accounts"
        },
        "enableFacebookServerSideTracking": "Enable Facebook Server Side Tracking",
        "facebook": "Facebook",
        "facebookInstagram": {
          "input": {
            "adAccount": {
              "infoMessage": "This selection influences which Meta-Pixel are available to select",
              "label": "Ad Account"
            },
            "adAccounts": {
              "label": "Ad Accounts for Adspends Import"
            },
            "businessManager": {
              "infoMessage": "This selection influences which Page, Instagram Account and Ad Accounts are available",
              "label": "Business Manager"
            },
            "facebookPage": {
              "label": "Facebook Page"
            },
            "facebookPixel": {
              "infoMessage": "The available Meta-Pixel of the selected Ad-Account.",
              "label": "Meta-Pixel"
            },
            "instagram": {
              "infoMessage": "This selection is optional.",
              "label": "Instagram Account"
            }
          },
          "label": "Facebook & Instagram Account"
        },
        "facebookOAuth": {
          "connect": "Connect",
          "connectedPopover": "User {user} connected",
          "disconnect": "Disconnect",
          "disconnectDialog": {
            "description": "Are you sure you want to disconnect your Facebook Account?",
            "title": "Disconnect"
          },
          "disconnectedPopover": "Not connected",
          "label": "Facebook",
          "loginAbortDialog": {
            "description": "Are you sure you want to discard these settings? If you proceed, your Facebook Account will not be connected.",
            "title": "Discard settings"
          },
          "missingPermissionsDialog": {
            "error": "Not all required permissions were granted.",
            "title": "Permissions missing"
          },
          "reconnect": "Reconnect"
        },
        "importFacebookAdSpends": "Import Facebook ad spend",
        "label": "Social Media",
        "page": {
          "input": "Page",
          "label": "Facebook Page"
        }
      },
      "googleAds": {
        "adAccount": {
          "input": "Ad Accounts",
          "label": "Google Ad Accounts"
        },
        "adAccounts": {
          "label": "Ad-Accounts for Adspends Import"
        },
        "enableGoogleServerSideTracking": "Enable Google Server Side Tracking",
        "importAdwordsAdspends": "Import Google Ads ad spend",
        "label": "Google",
        "loginAbortDialog": {
          "description": "Are you sure you want to discard these settings? If you proceed, your Google Account will not be connected.",
          "title": "Discard settings"
        },
        "managerAccount": {
          "label": "Manager Account"
        }
      },
      "googleAnalytics": {
        "account": {
          "dialog": {
            "content": "<b>Account:</b> {account}<br><b>Domain:</b> {domain}<br><b>View:</b> {view}<br>",
            "logout": "Disconnect account",
            "title": "Google Analytics Account"
          },
          "label": "Google Analytics Account",
          "properties": {
            "googleAnalyticsAccountId": "Google Analytics Account ID",
            "googleAnalyticsDatasetId": "Google Analytics DataSet ID",
            "googleAnalyticsPropertyId": "Google Analytics Property ID"
          }
        },
        "exportFacebookAdSpends": "Export Facebook ad spend to Google Analytics",
        "label": "Google Analytics",
        "measurementProtocol": {
          "label": "Google Analytics Measurement Protocol"
        },
        "property": {
          "label": "Google Analytics Property"
        },
        "webStream": {
          "label": "Google Analytics Web Stream"
        }
      },
      "oAuth": {
        "connect": "Connect",
        "connectedPopover": "User {user} connected",
        "disconnect": "Disconnect",
        "disconnectDialog": {
          "description": "Are you sure you want to disconnect your account?",
          "title": "Disconnect"
        },
        "disconnectedPopover": "Not connected",
        "facebookConnect": {
          "description": "Connect to Facebook in order to use your own profile",
          "title": "Connect to Facebook"
        },
        "reconnect": "Reconnect",
        "systemUser": {
          "description": "Uses the default ADDITIVE+ System User",
          "title": "Connect as System User"
        }
      },
      "reports": {
        "emailReceivers": {
          "description": "Campaign reports will be sent to the following addressees. Note that only users of the organisation will have access to the report.",
          "label": "Email receivers of the campaign reports"
        },
        "generationDay": {
          "description": "The day of the month on which the automated campaign reports are generated each month.",
          "error": "Value must be between 1 and 28",
          "inputLabel": "Day of the month",
          "label": "Day of the month for automated reporting"
        }
      },
      "title": "Settings",
      "tracking": {
        "code": "ADDITIVE+ APPS Snippet",
        "configuration": {
          "cookieLevel": {
            "description": "Which cookies may the ADDITIVE+ APPS snippet set in the browser of the visitor?<br> Detailed information about the cookies can be found <a class=\"blue font-medium\" href=\"{url}\" target=\"_blank\" rel=\"noopener noreferrer\">here</a>.",
            "options": {
              "all": "Allow all cookies",
              "essential": "Allow only essential cookies",
              "none": "Do not allow any cookies"
            },
            "title": "Cookie Allow-Level"
          },
          "sdkVersion": "SDK Version",
          "title": "Configuration",
          "tracking": {
            "description": "Here, you determine whether the ADDITIVE+ APPS snippet should record visitor sessions, page views, and conversions. This forms the basis for measuring the success of campaigns.",
            "title": "Record sessions and conversions",
            "warning": "WARNING: If you disable recording, no evaluation of campaign success will be possible!"
          }
        },
        "copySuccess": "Copied to clipboard",
        "crossDomainTracking": {
          "description": "Accesses by a visitor to different domains within a session should be grouped in order to be able to attribute all actions to the correct entry campaign. This is especially useful if visitors have to be directed to an external domain for the conversion. This setting allows you to define multiple domains to be considered part of a single session.",
          "header": "Hosts for \"cross-domain\" grouping",
          "label": "\"Cross-Domain\"-Sessions"
        },
        "downloadManual": "Download guide",
        "excludedIpAddresses": {
          "infoMessage": "IP addresses separated by comma",
          "label": "Blacklist (excluded IP addresses)"
        },
        "ignoredTrackingHosts": {
          "infoMessage": "Host URLs separated by comma",
          "label": "Blacklist (excluded tracking hosts)"
        },
        "label": "Integration Code"
      }
    },
    "stayAttribution": {
      "description": "In the following table, all inquiries made on your website and landing pages that can be found within ADDITIVE+ ANFRAGEN are analyzed and assigned to the corresponding advertising campaigns. Additionally, all resulting reservations are determined.<br><br>Taken into consideration are all inquiries with an arrival date in the period under review.",
      "navigation": {
        "arrival": {
          "description": "Taken into consideration are all inquiries with an arrival date in the period under review.",
          "title": "Arrival Date"
        },
        "enquiry": {
          "description": "Taken into consideration are all inquiries generated during the period under review.",
          "title": "Inquiry Date"
        }
      },
      "title": "Stay Attribution"
    },
    "toast": {
      "copySuccess": "Copied to clipboard",
      "success": "Action was completed successfully.",
      "unexpectedError": "An unexpected error occurred."
    },
    "toasts": {
      "loading": {
        "description": "Etwas Geduld bitte, wir sind fast fertig!",
        "title": "Das dauert länger als erwartet."
      }
    },
    "uiActions": {
      "back": "Back",
      "close": "Close",
      "confirm": "Confirm",
      "delete": "Delete",
      "edit": "Edit",
      "export": "Export",
      "info": "Information",
      "menu": "Menu",
      "more": "More",
      "reset": "Reset",
      "save": "Save",
      "saveAndPublish": "Save and publish",
      "search": "Search"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "No organization found",
        "organizationSettings": "Organization settings",
        "searchPlaceholder": "Search"
      },
      "appsList": {
        "goToApps": "Go to apps",
        "myApps": "My apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Discover other apps"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "This category cannot be deleted, because it still contains content.",
      "titleError": "Attention!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# subcategory} other {# subcategories}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Preview",
        "save": "Save"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# entry} other {# entries}}",
      "filterCount": "{count, plural, =1 {# entry} other {# entries}} found",
      "pages": "Page <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiDateFilter": {
      "label": "Observation period"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Custom date",
        "last_month": "Last month",
        "last_thirty_days": "Last 30 days",
        "last_week": "Last week",
        "this_month": "This month",
        "this_week": "This week"
      },
      "weekDaysShort": {
        "Fri": "Fri",
        "Mon": "Mon",
        "Sat": "Sat",
        "Sun": "Sun",
        "Thu": "Thu",
        "Tue": "Tue",
        "Wed": "Wed"
      }
    },
    "uiDatetimePicker": {
      "date": "Date",
      "endDate": "End date",
      "endTime": "End time",
      "error": {
        "beforeToday": "Past date",
        "false": "Invalid date"
      },
      "startDate": "Start date",
      "startTime": "Start time",
      "time": "Time"
    },
    "uiDiscardChanges": {
      "discardAction": "Discard",
      "message": "Do you want to discard the unsaved changes?",
      "title": "Attention!"
    },
    "uiEditor": {
      "actions": {
        "blockquote": "Quote",
        "bold": "Bold",
        "bulletList": "List",
        "code": "Code",
        "codeBlock": "Code",
        "headings": {
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Horizontal line",
        "italic": "Italic",
        "link": "Link",
        "numberedList": "Numbered list",
        "orderedList": "Numbered list",
        "placeholder": "Placeholder",
        "quote": "Quote",
        "redo": "Redo",
        "spacer": "Horizontal line",
        "underline": "Underline",
        "undo": "Undo",
        "unformat": "Remove formatting"
      },
      "colors": {
        "accent": "Accent color",
        "ambient": "Ambient color",
        "default": "No color",
        "main": "Main color"
      },
      "urlDialog": {
        "openInNewTab": "Open in new tab",
        "save": "Save",
        "text": "Text",
        "type": {
          "name": "Link type",
          "options": {
            "email": "Email",
            "mailto:": "Email",
            "tel": "Phone",
            "tel:": "Phone",
            "weblink": "Web link"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "This email address is not valid",
          "mailto:": "This email address is not valid",
          "tel": "This phone number is not valid",
          "tel:": "This phone number is not valid",
          "weblink": "This URL is not valid"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filter",
      "daterange": {
        "end": "until",
        "start": "from"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Value exceeds the recommended length of {recommendedLength} characters"
    },
    "uiFormDialog": {
      "save": "Save"
    },
    "uiFormMessage": {
      "characters": "{charCount} characters"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {One element} other {# elements}} deleted",
      "common": {
        "bulkDelete": {
          "action": "Delete all",
          "description": "The selected contents will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete the selected contents?"
        },
        "deleteContent": {
          "description": "The content will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Attention!"
        }
      },
      "conflict_errors": {
        "person": {
          "description": "This person can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this person.",
          "title": "This person is in use"
        }
      },
      "delete": "Delete",
      "empty": "There are no existing contents.<br/>Click the button below to create a new content.",
      "error": "An unexpected error occurred.",
      "errors": {
        "rate_in_use": "This rate cannot be deleted, since it is used in a price timespan.",
        "title": "Attention!",
        "used_in_post": "This person cannot be deleted, since it is used in a post.",
        "vouchers_left": "This content cannot be deleted, since it is used in a voucher."
      },
      "noMatch": "Your search did not return any results.",
      "properties": {
        "published": "published",
        "unpublished": "unpublished"
      }
    },
    "uiMonthDayPicker": {
      "day": "Day",
      "month": "Month"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "More filters",
        "error": {
          "retryButtonText": "An error has occurred! Try again now"
        },
        "navigationDrawer": {
          "title": "More filters"
        }
      },
      "searchInputDefaultPlaceholder": "Search"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Back",
        "endOnboarding": "Get started now",
        "next": "Next",
        "skip": "Skip"
      }
    },
    "uiPublishResource": {
      "description": "Choose which language you would like to publish. You can also limit the time frame for the publication.",
      "descriptionNoLang": "Select the time to publish the content.",
      "errors": {
        "after": "Must be after start date",
        "before": "Must be before end date",
        "required": "Required"
      },
      "languages": "Languages",
      "limitTime": "Limit the time frame",
      "save": "Save",
      "settings": "Settings",
      "title": "Publish"
    },
    "uiRangeSlider": {
      "errorMessage": "The value must be a positive integer",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "No data available",
      "error": "An error has occurred",
      "retry": "Try again",
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiTags": {
      "error": "This value has already been added",
      "interests": {
        "interests": "Interests",
        "travelMotivation": "Travel motives",
        "travelTime": "Seasons"
      },
      "placeholder": "Add tag",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Invalid time value",
      "timeUnit": ""
    },
    "uiToast": {
      "success": "Action completed successfully",
      "unexpectedError": "An unexpected error has occurred"
    }
  };
});